<div class="background">
  <div class="left-bg">
    <img
      class="bottom-right-watermark"
      src="../../assets/images/holadoc-corazon.svg"
    />
    <div class="login-container">
      <div class="custom-container">
        <mat-card class="login-card">
          <mat-card-content>
            <div>
              <img src="../../assets/images/logo.svg" width="90px" />
            </div>
            <form class="form login-form" [formGroup]="loginForm" (ngSubmit)="login()">
              <p *ngIf="showError" style="font-weight: bold; color: red">
                {{ error }}
              </p>
              
              <div class="row">
                <!-- Email -->
                <mat-form-field class="col-12 login-form-field">
                  <mat-label>
                    {{ 'Email' | translate }}
                  </mat-label>
                  <input
                    type="text"
                    matInput
                    autocomplete="email"
                    formControlName="email"
                    required
                  />
                  <mat-error *ngIf="loginForm.get('email').hasError('required')">
                    {{ "Email is Required." | translate }}
                  </mat-error>
                  <mat-error *ngIf="loginForm.get('email').hasError('pattern')">
                    {{ "Please enter a valid email" | translate }}
                  </mat-error>
                </mat-form-field>
                <!-- Password -->
                <mat-form-field class="col-12 login-form-field">
                  <mat-label>
                    {{ 'Password' | translate }}
                  </mat-label>
                  <input
                    type="password"
                    matInput
                    autocomplete="password"
                    formControlName="password"
                    required
                  />
                  <mat-error *ngIf="loginForm.get('password').hasError('required')">
                    {{ "Password is Required." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.get('password').hasError('minlength')"
                  >
                    {{ "Password should be minimum of 8 characters." | translate }}
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.get('password').hasError('maxlength')"
                  >
                    {{ "Password should be maximum of 15 characters." | translate }}
                  </mat-error>
                  <mat-error *ngIf="loginForm.get('password').hasError('pattern')">
                    {{ "Please enter valid password." | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
  
  
  
              <div class="row mt-4 align-items-center">
                <div class="col-5">
                  <mat-checkbox
                    color="primary"
                    class="login-form-remember"
                    formControlName="remember"
                    >
                    <span class="rememberMeText">
                      {{ "Remember me" | translate }}
                    </span>
                  </mat-checkbox>
                </div>
                <div class="col-7">
                  <a
                    style="color: #46535b; text-decoration: none"
                    routerLink="/forgot-pwd"
                    >
                    <span class="forgot-password-text">
                      {{ "Forgot password?" | translate }}
                    </span>
                  </a>
                </div>
              </div>
  
  
              <div class="login-button-row">
                <button [disabled]="isLoading" mat-raised-button>
                  <span class="loginButton-text">
                    {{ "Login" | translate }}
                  </span>
                </button>
              </div>
            </form>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
