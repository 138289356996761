<div class="container-fluid reset-container">
  <div class="container mt-5">
    <div class="row align-content-center align-items-center">
      <div style="text-align: center">
        <h4 class="heading">{{ "Set New Password" | translate }} </h4>
      </div>
    </div>

    <div class="row align-content-center align-items-center justify-content-center">
      
      @if ( !hideForm ) {
        <mat-card class="reset-card">
          <mat-card-content>
            <form
            class="example-form"
            [formGroup]="changepasswordForm"
            (ngSubmit)="changePasswordSubmit()"
          >
            <p style="text-align: initial; color: #46535b; font-weight: 600">
              {{
                "Please enter your new password and confirm password." | translate
              }}
            </p>
    
            <mat-form-field
              class="example-full-width app-form-field"
              style="width: 100%"
            >
              <input
                [type]="oldPasswordHide ? 'password' : 'text'"
                matInput
                placeholder="{{ 'Old Password' | translate }}"
                formControlName="oldPassword"
                required
              />
              <mat-icon matSuffix (click)="oldPasswordHide = !oldPasswordHide">
                {{ oldPasswordHide ? "visibility_off" : "visibility" }}
              </mat-icon>
              
              @if ( changepasswordForm.get('oldPassword').hasError('required') ) {
                <mat-error>
                  {{ "Old Password is Required." | translate }}
                </mat-error>
              }
            </mat-form-field>
              <br />
            <mat-form-field
              class="example-full-width app-form-field"
              style="width: 100%"
            >
              <input
                [type]="passwordHide ? 'password' : 'text'"
                matInput
                placeholder="{{ 'New Password' | translate }}"
                formControlName="password"
                required
              />
              <mat-icon matSuffix (click)="passwordHide = !passwordHide">{{
                passwordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
              @if (  changepasswordForm.get('password').hasError('required') ) {
                <mat-error>
                  {{ "Password is Required." | translate }}
                </mat-error>
              }

              @if ( changepasswordForm.get('password').hasError('pattern') ) {
                <mat-error>
                  {{ "Please enter valid password." | translate }}
                </mat-error>
              }

              @if ( changepasswordForm.get('password').hasError('minlength') ) {
                <mat-error>
                  {{ "Password should be minimum of 8 characters." | translate }}
                </mat-error>
              }

            </mat-form-field>
            <br />
            <mat-form-field
              class="example-full-width app-form-field"
              style="width: 100%"
            >
              <input
                [type]="confirmPasswordHide ? 'password' : 'text'"
                matInput
                placeholder="{{ 'Confirm Password' | translate }}"
                formControlName="confirmPassword"
                required
              />
    
              <mat-icon
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                >{{
                  confirmPasswordHide ? "visibility_off" : "visibility"
                }}</mat-icon
              >

              @if ( changepasswordForm.get('confirmPassword').hasError('required') ) {
                <mat-error>
                  {{ "Confirm Password is Required." | translate }}
                </mat-error>
              }

              @if ( changepasswordForm.get('confirmPassword').hasError('mustMatch') ) {
                <mat-error>
                  {{ "Password and confirm password do not match" | translate }}
                </mat-error>
              }
            </mat-form-field>
            
            @if ( showError ) {
              <p style="font-weight: bold; color: red">
                {{ errorMsg | translate }}
              </p>
            }
    
            <div class="reset-button-row">
              <button
                [disabled]="loading"
                mat-raised-button
                style="margin-left: 5%"
              >
                {{ "Change" | translate }}
              </button>
            </div>
          </form>
          </mat-card-content>
        </mat-card>
      }
    </div>
  </div>
</div>
