import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, Event, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "./core/i18n.service";
import { environment } from "src/environments/environment";
import { filter, map, switchMap } from "rxjs/operators";
import { merge } from "rxjs";
import { Title } from "@angular/platform-browser";
import version from "../../package.json";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  currentUrl: string;
  previousUrl: string;
  public version: any = version;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    // console.log(`Versión ${version}`);
  }

  ngOnInit() {
    // Setup translations
    console.log("App Component initialized");
    console.log("Versión", this.version);

    this.i18nService.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );

    if (localStorage.getItem("language") === null) {
      this.i18nService.language = environment.defaultLanguage;
    } else {
      this.i18nService.language = localStorage.getItem("language");
    }

    this.loadRouting();
  }

  public loadRouting(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        let token =
          sessionStorage.getItem("credentials") ||
          localStorage.getItem("credentials");
        if (
          token != null &&
          (this.currentUrl === "/login" || this.currentUrl === "/reset-pwd")
        ) {
          this.router.navigate(["/"]);
        }
      }
    });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
