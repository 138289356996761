import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { HomeModule } from "./home/home.module";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { CoreModule } from "./core/core.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptor } from "./http.interceptor";
import { SharedModule } from "./shared/shared.module";
import { HeaderComponent } from "./header/header.component";
import { TranslateModule } from "@ngx-translate/core";
import { MatTabsModule } from "@angular/material/tabs";
import { CommonService } from "./common.services";
import { JwtModule } from "@auth0/angular-jwt";
import { CredentialsService } from "./core/credentials.service";
import { LoaderInterceptorService } from "./loader-interceptor.service";
import { LoaderComponent } from "./loader/loader.component";

import { provideLottieOptions, LottieComponent } from "ngx-lottie";
import player from "lottie-web";

import { CustomMatPaginatorIntl } from "./custom-mat-paginator-int";
import { MatPaginatorIntl } from "@angular/material/paginator";
import localeEs from "@angular/common/locales/es";
import localeEn from "@angular/common/locales/en";
import { registerLocaleData } from "@angular/common";
import {
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  BreadcrumbService,
} from "xng-breadcrumb";

import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { AuthenticationGuard } from "./core/authentication.guard";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MAT_BUTTON_TOGGLE_GROUP } from "@angular/material/button-toggle";
import { MAT_SELECT_CONFIG, MAT_SELECT_SCROLL_STRATEGY } from "@angular/material/select";

registerLocaleData(localeEs);
registerLocaleData(localeEn);

export function tokenGetter() {
  const savedCredentials =
    sessionStorage.getItem("credentials") ||
    localStorage.getItem("credentials");
  if (savedCredentials) {
    return (this._credentials = JSON.parse(savedCredentials).token);
  }
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    CoreModule,
    SharedModule,
    MatTabsModule,
    BrowserAnimationsModule,
    LottieComponent,
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    
  ],
  providers: [
    AuthenticationGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    CommonService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntl,
    },
    BreadcrumbService,
    provideLottieOptions({
      player: () => player,
    }),
    // provideCharts(withDefaultRegisterables()),
    
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "fill", hideRequiredMarker: true, subscriptSizing: "dinamyc" } },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { hideSingleSelectionIndicator: true } },
    { provide: MAT_SELECT_CONFIG, useValue: { hideSingleSelectionIndicator: true } }
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
