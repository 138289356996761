<div class="avatar" 
  [ngStyle]="{
    'background-color': backgroundColor,
    'width': width,
    'height': height
  }">
  <img *ngIf="src" [src]="src" alt="Avatar" />
  <span *ngIf="!src" [ngStyle]="{ 'font-size': fontSize }">
    {{ initials }}
  </span>
</div>