import { Component, Input } from "@angular/core";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
  @Input() src: string;
  @Input() name: string;
  @Input() backgroundColor: string = "#ccc";
  @Input() height: string = "40px";
  @Input() width: string = "40px";
  @Input() fontSize: string = '15px';

  get initials(): string {
    if (!this.name) return "";
    const names = this.name.split(" ");
    const initials = names.map((n) => n.charAt(0).toUpperCase()).join("");
    return initials;
  }
}
