import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthenticationGuard } from "./core/authentication.guard";
import { BreadcrumbComponent, BreadcrumbItemDirective } from "xng-breadcrumb";

import { resetPasswordResolver } from "./resolvers/reset-password.resolver";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./home/home.module").then((h) => h.HomeModule),
    canActivate: [AuthenticationGuard],
    data: { breadcrumb: { label: "Home" } },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: "forgot-pwd",
    component: ForgotPasswordComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: "reset-pwd",
    component: ResetPasswordComponent,
    data: { breadcrumb: { skip: true } },
    resolve :[
      resetPasswordResolver
    ]
  },
  {
    path: "verify",
    component: ResetPasswordComponent,
    data: { breadcrumb: { skip: true } },
  },
  {
    path: "**",
    component: PageNotFoundComponent,
    data: { breadcrumb: { skip: true } },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    BreadcrumbComponent,
    BreadcrumbItemDirective,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
